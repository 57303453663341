import { CommonMargin } from 'components/_layout/CommonMargin';
import { Section } from 'components/_layout/Section/Section';
import * as Common from 'components/_universal/Common';
import { useBreakpoint } from 'utils/hooks/useBreakpoints';
import { ArrowButton } from 'components/_universal/ArrowButton';
import { navigate } from 'gatsby';
import { ROUTE_BLOG } from 'shared/paths';
import { B3, H1 } from 'styles/Typography.styled';
import styled from 'styled-components';
import React from 'react';
import { SocialMediaIcons } from 'components/_layout/SocialMediaIcons';

export const BigNumberHeader = styled(H1)``;

export const SocialMediaIconsLeft = styled(SocialMediaIcons)`
    justify-content: flex-start;
`;

export default () => {
    const isMobile = useBreakpoint('md');

    return (
        <CommonMargin>
            <Section isFirst>
                <Common.Div
                    flex="row"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Common.Div flex={isMobile ? 'column' : 'row'} gap="30px">
                        <BigNumberHeader>Success</BigNumberHeader>
                        <Common.Div flex="column">
                            <B3 mb={4}>
                                Go to the blog page using the button below.
                            </B3>
                            <ArrowButton
                                onClick={() => navigate(ROUTE_BLOG)}
                                text="blog page"
                            />
                            <SocialMediaIconsLeft />
                        </Common.Div>
                    </Common.Div>
                </Common.Div>
            </Section>
        </CommonMargin>
    );
};
